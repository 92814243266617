
import { ref, reactive, onMounted, toRefs} from 'vue'
import { defineComponent } from 'vue';
import {jsPlumb} from 'jsplumb'
import useEchar from '../Each/userEach'

export default defineComponent({
    setup(){
        const myChart = ref()
        const option ={
            title: {
                x: "center",
                text: '注册用户数量',
                // subtext: "展示最近半年注册用户数量",
                textStyle:{
                color:"#8DB6DB"
                    },
                subtextStyle: {
               color: '#8DB6DB',
                }        
            },
            xAxis: {
                type: 'category',
                boundaryGap: false,
                data: ['六月', '七月', '八月', '九月', '十月', '十一月', '十二月'],
                axisLabel:{//修改坐标系字体颜色
                show:true,
                textStyle:{
                color:"#8DB6DB"
                },
                splitLine: {
                    show: false,
                    },
            },
            },
            tooltip: { // 鼠标悬浮提示框显示 X和Y 轴数据
                trigger: 'axis',
                backgroundColor: 'rgba(32, 33, 36,.7)',
                borderColor: 'rgba(32, 33, 36,0.20)',
                borderWidth: 1,
                textStyle: { // 文字提示样式
                color: '#fff',
                fontSize: '12'
                },
                axisPointer: { // 坐标轴虚线
                type: 'cross',
                label: {
                    backgroundColor: '#6a7985'
                }
            }
        },
            yAxis: {
                type: 'value',
                axisLabel:{//修改坐标系字体颜色
                show:true,
                textStyle:{
                color:"#8DB6DB"
                }
            },

            },
            series: [
                {
                data: [1, 4, 6, 9, 7, 13, 12],
                type: 'line',
                areaStyle: {}
                }
            ],
              categoryAxis: {
        //   position: 'bottom',    // 位置
        //   nameLocation: 'end',   // 坐标轴名字位置，支持'start' | 'end'
        //   boundaryGap: true,     // 类目起始和结束两端空白策略
          axisLine: {            // 坐标轴线
              show: false,        // 默认显示，属性show控制显示与否
              lineStyle: {       // 属性lineStyle控制线条样式
                  color: '#48b',
                  width: 2,
                 type: 'solid'
             }
           }
            },
            };
        //用这个原因是防止出现  Initialize failed: invalid dom.报错
        onMounted(() => {
        //现在就是调用方法那么简单
        myChart.value = useEchar(option, 'main')
        }) 
       return{
        myChart
       }
    }
});
