import axios from 'axios';
import { ElMessage } from 'element-plus';


enum MEGS{
    "操作成功" = 200,
    "用户或密码错误" = 201,
    "服务器开小差了" = 500
}

//创建http实例
const tokens = localStorage.getItem("token")
const $http = axios.create({
    baseURL: window['ApiBaseUrl'],
    timeout: 10000,
    headers: {
        "Content-Type":"application/json;charset=utf-8",
        "token": tokens
    }
})



//请求拦截
$http.interceptors.request.use(config=>{
    config.headers = config.headers || {}
    if (localStorage.getItem("token")){
        // console.log(localStorage)
        config.headers.token = localStorage.getItem("token") || ""
    }
    return config
})



//根据请求状态
$http.interceptors.response.use(res=>{
    console.log(res)
    const code:number = res.data.status_code
    console.log(code)
    if (code == 2001){
        // ElMessage.error(res.data.msg)
        return Promise.reject(res.data.msg)
    }
    return res
},err=>{
    return Promise.reject(MEGS[500])
})



export default $http 
