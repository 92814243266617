import type { FormInstance } from "element-plus";
import {ref} from 'vue';

export interface loginFormInt{
    email:string,
    password:string,
    codes:string,

}

export  class InitData{
    loginForm:loginFormInt = {
        email: '',
        password: '',
        codes: '',
    }
    loginFormRef=ref<FormInstance>()
}
