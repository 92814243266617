import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import LoginView from '../views/LoginView.vue'
import RegisTerView from '../views/RegisTerView.vue'
import MappingView from '../views/MappingView.vue'
import MappingLibraryView from '../views/MappingLibraryView.vue'
import VisualizationView from '../views/VisualizationView.vue'
import RegulatoryView from '../views/RegulatoryView.vue'
import HistoryDataView from '../views/HistoryDataView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    redirect: "mappinglibrary",
    children:[
      {
        path: 'mappinglibrary',
        name: 'mappinglibrary',
        meta: {
          icon: "Collection",
          isShow:true,
          isTitle: true,
          title: "映射特征库",
          requireAuth: true
        },
        component: MappingLibraryView
      },
      {
            path: 'mapping',
            name: 'mapping',
            meta: {
              icon: "Switch",
              isShow: true,
              title: "特征映射",
              requireAuth: true
            },
            component: MappingView,
      },
      {
            path: 'historydata',
            name: 'historydata',
            meta: {
              icon: "Clock",
              isShow: true,
              title: "提交历史",
              requireAuth: true
            },
            component: HistoryDataView,
      },
      {
        path: 'visualization',
        name: 'visualization',
        meta: {
          icon: "Histogram",
          isShow:true,
          isTitle: true,
          title: "可视化数据",
          requireAuth: true
        },
        component: VisualizationView
      }
   ],
    component: HomeView,
  },
  {
    path: '/register',
    name: 'register',
    meta:{
      iftoken: true
    },
    component: RegisTerView
  },
  {
    path: '/login',
    name: 'login',
    meta: {
      meta:{
        iftoken: true
      }
    },
    component: LoginView
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  /**
   * 未登录则跳转到登录页
   * 未登录跳转到登录页,也可以通过axios的响应拦截器去实现,但是会先在当前页面渲染一下,再跳转到登录页,会有个闪动的现象
   * 这里通过路由守卫的方式,不会在当前页闪现一下,但是需要在每个路由组件添加一个是否需要登录的标识位,如本项目中的requireAuth字段
   */
  const token = localStorage.getItem("token");
  if (to.matched.some((auth) => auth.meta.requireAuth)) {
      if (token) {
          console.log(to.path)
          if (to.path === "/login"){
            next({
              path: "/mappinglibrary"
          });
          }else{
            next();
          }
      } else {
        console.log(to.path)
          next({
              path: "/login"
          });
      }
  } else {
    if (token && to.path === "/login"){
      next({
        path: "/mappinglibrary"
    });
    }else{
      next();
    }
  }
})
export default router
