
import {getCurrentInstance, defineComponent,reactive,toRefs,ref } from 'vue';
import {useStore} from "vuex"
import  { InitData }  from "../numtype/login";
import   { CodeData }  from "../numtype/code";
import { ElMessage } from 'element-plus';
import { logins, getcode } from '../numhttp/api';
import {useRouter}  from 'vue-router';
import {Base64} from 'js-base64'


export default defineComponent({
    setup(){
        const store = useStore()
        const datas = reactive(new InitData());
        const router = useRouter()
        const ruless= {
                password:[
                    { required: true, message: "请输入密码", trigger:"blur"}
                ],
                email:[
                    { required: true, message: "请输入邮箱", trigger:"blur"}
                ],
                codes:[
                    { required: true, message: "请输入验证码", trigger:"blur"}
                ]
            };
        //登录
        const submitForms = ()=>{
            const user = reactive(new InitData());
            datas.loginFormRef?.validate((valid)=>{
               if(valid) {
                  user.loginForm.email = datas.loginForm.email
                  user.loginForm.codes = datas.loginForm.codes
                  user.loginForm.password = Base64.encode(datas.loginForm.password)
                  logins(user.loginForm).then(res=>{
                       console.log("返回值" + res)
                       localStorage.setItem("token", res.data.msg.token)
                       localStorage.setItem("user", res.data.msg.user)
                       localStorage.setItem("email", res.data.msg.email)
                       localStorage.setItem("source", res.data.msg.source)
                       store.commit("increment", res.data.msg.user)
                       router.push("/mappinglibrary")
                       console.log(res)
                    //    ElMessage.success("登录成功！")
                   },err=>{
                      console.log(err)
                      ElMessage.error(err)
                   })
               
               }                        
            })
        };
        //获取验证码
        const show = ref(true);
        const timer = ref();
        const TIME_COUNT = ref(300);
        const count = ref();
        const Verification = () => {
            if (!timer.value) {
                const code = reactive(new CodeData());
                code.CodeForm.email = datas.loginForm.email
                getcode(code.CodeForm).then(res =>{
                    count.value = TIME_COUNT.value;
                    show.value = false;
                    ElMessage.success("验证码请求已发送")
                    timer.value = setInterval(() => {  
                    if (count.value > 0 && count.value <= TIME_COUNT.value) {
                        count.value--;
                        console.log("count----", count);
                    } else {
                        show.value = true;
                        clearInterval(timer.value);
                        timer.value = null;
                    }}, 1000) 
                },err=>{
                    show.value = true;
                    console.log(err)
                    ElMessage.error(err); 
                }); 
            }
        } 
        const token = localStorage.getItem("token");
        if (token) {
            console.log("有token")
            router.push("/mappinglibrary")
        }
        return {
            ...toRefs(datas),
            ruless,
            submitForms,
            show,
            Verification,
            timer,
            TIME_COUNT,
            count
        };
    },
});
