
import { getdata } from '../numhttp/api';
import {onBeforeMount, reactive, computed,toRefs, ref} from 'vue'
import { defineComponent } from 'vue';
import  { InitData }  from "../numtype/xianshi";
import { ElMessage } from 'element-plus';
import * as _ from 'lodash'


export default defineComponent({
    setup(){
      //防抖
      const fangdou = _.debounce(click, 500, {
      leading: true,  // 延长开始后调用
      trailing: false  // 延长结束前调用
    })
    function click() {
        //响应点击
      console.log("123")
    }
      //数据总数
      const total = ref(0)
      //搜索内容
      const input_search = ref('')
      let tableData = ref([])
      // 隔行颜色改变
      const tableRowClassName = ({ row, rowIndex }) => {
        console.log(rowIndex)
        if ((rowIndex + 1) % 2 === 0) {
              return 'oddRow';
        }else{
          return 'evenRow';
        }
      }
      //请求数据
      const get_payload = {
         "search":"",
         "page":1,
         "per_page":10
      }
      //生命周期
    onBeforeMount(() => {
      lista();
    });
    const lista = () => {
      getdata(get_payload).then(res=>{
              // console.log(res.data.data.data)
              tableData.value = res.data.data.data
              total.value = res.data.data.total
              // console.log("请求返回数据",tableData)
              },err=>{
                // console.log(err)
                ElMessage.error(err)
      })
    }
      //分页处理
      const currentPage = ref(1)
      const pageSize = ref(10)
      //数据截取
      const datelist = computed(() =>{
               return tableData.value.slice((currentPage.value -1)*pageSize.value,
                currentPage.value*pageSize.value)
      })
      const handleSizeChange = (val) => {
        // console.log(`${val} items per page`)
      }
      const handleCurrentChange = (val) => {
        currentPage.value = val

      }
      const inputsreach = ()=> {
        get_payload.search =  input_search.value
        getdata(get_payload).then(res=>{
              // console.log(res.data.data.data)
              tableData.value = res.data.data.data
              total.value = res.data.data.total
              // console.log("请求返回数据",tableData)
              },err=>{
                // console.log(err)
                ElMessage.error(err)
      })

      }
       return{
        datelist,
        currentPage,
        pageSize,
        handleSizeChange,
        handleCurrentChange,
        total,
        tableRowClassName,
        input_search,
        inputsreach
      }
    },
});

