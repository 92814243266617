
import {computed, ref } from 'vue';
import { defineComponent } from 'vue';
import {useRouter} from 'vue-router'
import { useStore} from "vuex"


export default defineComponent({
    setup(){
      const router = useRouter();
      const activeIndex = ref("")
      if (router.currentRoute.value.name === "" ){
           activeIndex.value = "mappinglibrary"
      }else{
           activeIndex.value = String(router.currentRoute.value.name)
      }
      const dialogVisible = ref(false)
      const handleSelect = (key, keyPath) => {
        console.log(key, keyPath)
      }
      // let userinfo = localStorage.getItem("msgs")
      // console.log(userinfo)
      const infos = {
        "所属机构": localStorage.getItem("source"),
        "用户名" : localStorage.getItem("user"),
        "邮箱" : localStorage.getItem("email"),
      }
      console.log(infos)
      const store = useStore()
      const users = localStorage.getItem("user")
      const list = router.getRoutes().filter(v=>v.meta.isShow)
      //注销登录
      const loginout = () =>{
          console.log("退出登录")
          localStorage.setItem("token", "")
          localStorage.setItem("user","")
          router.push("/login")
      }
        return {
            activeIndex,
            list,
            dialogVisible,
            infos,
            loginout,
            users
        };
    },
});
