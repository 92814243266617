
import { defineComponent,reactive,toRefs,ref } from 'vue';
import  {InitDatas}  from "../numtype/register";
import  {CodeData}  from "../numtype/code";
import { ElMessage } from 'element-plus';
import {registers} from '../numhttp/api';
import {getcode} from '../numhttp/api';
import {useRouter}  from 'vue-router';
import {Base64} from 'js-base64';
import {useStore} from "vuex";


export default defineComponent({
    setup(){
        const store = useStore()
        const datas = reactive(new InitDatas());
        const router = useRouter()
        const ruless= {
                email:[
                    { required: true, message: "请输入注册邮箱", trigger:"blur"}
                ],
                password:[
                    { required: true, message: "请输入密码", trigger:"blur"}
                ],
                che_password:[
                    { required: true, message: "请输入确认密码", trigger:"blur"}
                ],
                code_num:[
                    { required: true, message: "请输入验证码", trigger:"blur"}
                ]
            };
        //注册
        const submitForms = ()=>{
            const user = reactive(new InitDatas());
            datas.RegisterFormRef?.validate((valid)=>{
               if(valid) {
                  //判断两次密码是否一致
                if (datas.RegisterForm.password === datas.RegisterForm.che_password){
                    if (datas.RegisterForm.code_num.length === 6 ){
                        user.RegisterForm.email = datas.RegisterForm.email
                        user.RegisterForm.che_password = datas.RegisterForm.che_password
                        user.RegisterForm.code_num = datas.RegisterForm.code_num
                        user.RegisterForm.password = Base64.encode(datas.RegisterForm.password)
                        registers(user.RegisterForm).then(res=>{
                            localStorage.setItem("token",res.data.msg.token)
                            localStorage.setItem("user",res.data.msg.user)
                            router.push("/mappinglibrary")
                            console.log(res)
                            store.commit("increment", res.data.msg.user)
                            //    ElMessage.success("注册成功！")
                        },err=>{
                            console.log(err)
                            ElMessage.error(err)
                        })
                    }else{
                        ElMessage.error("未检测到验证码")
                    }
                }else{
                    ElMessage.error("两次密码不一致")
                }
               }                        
            })
        };
        //获取验证码
        const show = ref(true);
        const timer = ref();
        const TIME_COUNT = ref(300);
        const count = ref();
        const Verification = () => {
            if (!timer.value) {
                const code = reactive(new CodeData());
                code.CodeForm.email = datas.RegisterForm.email
                getcode(code.CodeForm).then(res =>{
                    count.value = TIME_COUNT.value;
                    show.value = false;
                    ElMessage.success("验证码请求已发送")
                    timer.value = setInterval(() => {  
                    if (count.value > 0 && count.value <= TIME_COUNT.value) {
                        count.value--;
                        console.log("count----", count);
                    } else {
                        show.value = true;
                        clearInterval(timer.value);
                        timer.value = null;
                    }}, 1000) 
                },err=>{
                    show.value = true;
                    console.log(err)
                    ElMessage.error(err); 
                }); 
            }
        } 
        return {
            ...toRefs(datas),
            ruless,
            submitForms,
            show,
            Verification,
            timer,
            TIME_COUNT,
            count
        };
    },
});
