import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'

interface States{
    user:string
}

export const store=createStore<States>({
    plugins:[createPersistedState({
        storage: window.sessionStorage,
        reducer(val){
            return{
                user: val.user
            }
        }
    })],
    state(){
       return{
         user: "",
       }
    },
    mutations:{
        increment(state, key){
            state.user = key
        }
    }
})


