import type { FormInstance } from "element-plus";
import {ref} from 'vue';

export interface CodeFormInt{
    email:string,
}

export  class CodeData{
    CodeForm:CodeFormInt = {
        email: '',
    }
    CodeFormRef=ref<FormInstance>()
}