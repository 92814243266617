import type { FormInstance } from "element-plus";
import {ref} from 'vue';

export interface RegisterFormInt{
    email:string,
    password:string,
    che_password:string,
    code_num:string,
}

export  class InitDatas{
    RegisterForm:RegisterFormInt = {
        email: '',
        password: '',
        che_password: '',
        code_num: '',
    }
    RegisterFormRef=ref<FormInstance>()
}
