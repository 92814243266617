
import { computed, reactive, ref } from 'vue'
import { defineComponent } from 'vue';
import {UploadInstance, FormInstance, FormRules, UploadProps, UploadRawFile,ElMessage } from 'element-plus';
import { uploadcheunkFile, down_file, history_date } from '../numhttp/api';
import {useStore} from "vuex"
import JsonTree from'vue-json-tree';

export default defineComponent({
  components: {
    JsonTree
  },
    setup(){
      //文件id
      const file_id = ref(0)
      //文件名称
      const filename = ref({
        filename: ""
      })
      const filenames = ref({
        filename: ""
      })
      const store = useStore()
      const users = ref("")
      const get_user = localStorage.getItem("user")
      if (get_user){
        users.value = get_user
      }
       //界面数据
       let tableData = ref([])
       //文件列表
       let file_date_list = ref([])
      //请求历史数据
      const history_payload = ref({
        user: users.value
      })
      history_date(history_payload.value).then(res=>{
            console.log(res.data)
            tableData.value = res.data.data
      },err=>{
        ElMessage.error("历史数据获取失败");
      })
      //刷新数据
      const refresh_date = ()=>{
        history_date(history_payload.value).then(res=>{
            tableData.value = res.data.data
            console.log("数据刷新了")
      },err=>{
        ElMessage.error("历史数据获取失败");
      })
      }
        //文件名称
        const file_input  = ref("")
        //数据提交初始化
        let formData = new FormData();
        //转换提交状态
        const dialogVisible = ref(false)
        const dialogVisibles = ref(false)
        const ruleForm = reactive({
            source: '',
            name: ''
            })
        const rules = reactive<FormRules>({
                name: [
                    { required: true, message: '请输入转换标题', trigger: 'blur' },
                    { min: 3, max: 8, message: '长度为3-8个字符', trigger: 'blur' },
                ],
                source: [
                    {
                    required: true,
                    message: '请输入机构名称',
                    trigger: 'change',
                    },
                ],
        })
        //颜色
        const tableRowClassName = ({ row, rowIndex }) => {
        console.log(rowIndex)
        if ((rowIndex + 1) % 2 === 0) {
              return 'oddRow';
        }else{
          return 'evenRow';
        }
      }
      //添加文件
       const filechange = (file_list) =>{
             console.log("新文件",file_list)
             formData.set("file","")
             formData.append("file", file_list.raw)
             ruleForm.name = file_list.name
             console.log(ruleForm.name)
             file_input.value = file_list.name
             console.log(file_list.name)

       }
       //文件上传数超限制
       const handleExceed = (file_list, raw_file) =>{
             console.log("更新文件名",file_list[0].raw)
             formData.append("file", file_list[0])
             ruleForm.name = file_list[0].name
             console.log("更新文件",ruleForm.name)
             file_input.value = file_list[0].name
            
       }
       //按钮防抖
       const isButtonDisabled = ref(false)
       
       //提交上传文件
       const upload_file = () =>{
          console.log(ruleForm.name, ruleForm.source)
          if (ruleForm.name && ruleForm.source) {
            const suffix = ruleForm.name.split(".")[1]
            if (!["xlsx","xls"].includes(suffix)){
                 ElMessage.warning('只能上传excel文件')
            }else{
              //组装数据
              console.log("提交数据前查看",ruleForm.name)
              formData.append("name", ruleForm.name)
              formData.append("source", ruleForm.source)
              formData.append("user", users.value)
              console.log(formData)
              isButtonDisabled.value = true 
              //上传文件接口
              uploadcheunkFile(formData).then(res =>{
                ElMessage.success("文件上传成功");
                isButtonDisabled.value = false
                location. reload()
              },err =>{
                ElMessage.error("文件上传失败");
                isButtonDisabled.value = false 
              })
            }
          }else{
              ElMessage.error("您还未选择上传文件或机构名称");
            }
       }
       //文件上传
       const add_date = (files)=>{
        console.log("选择文件时",files)
       }
       //模板文件下载
       const down_template = () =>{
            down_file(filename.value).then(res =>{
              const conent = res.data
              const blob = new Blob([conent])
              const link = document.createElement('a')
              const fileName = "映射上传模板.xlsx"
              link.download = fileName // a标签添加属性
              link.style.display = 'none'
              link.href = URL.createObjectURL(blob)
              document.body.appendChild(link)
              link.click() // 执行下载
              URL.revokeObjectURL(link.href) // 释放url
              document.body.removeChild(link) // 释
              console.log("模板下载完成")
            },err =>{
              ElMessage.error("模板下载失败");
            })
       }
       //下载转换好的数据
       const handleDown = ids =>{
          // filename.value.filename = ids + 1
          // console.log("下载文件id:",ids)
          // //通过id获取文件名
          // const down_files = ref("")
          // for (let item of tableData.value){
          //   if (JSON.parse(JSON.stringify(item)).id == filename.value.filename ){
          //      filenames.value.filename = JSON.parse(JSON.stringify(item)).rest_name
          //   }
          // }
          filenames.value.filename = ids.rest_name
          console.log("下载的文件名:",filenames.value.filename)
          down_file(filenames.value).then(res =>{
                console.log(filenames.value)
                const conent = res.data
                const blob = new Blob([conent])
                const link = document.createElement('a')
                link.download = filenames.value.filename // a标签添加属性
                link.style.display = 'none'
                link.href = URL.createObjectURL(blob)
                document.body.appendChild(link)
                link.click() // 执行下载
                URL.revokeObjectURL(link.href) // 释放url
                document.body.removeChild(link) // 释
                console.log(filenames.value.filename +"下载完成")
              },err =>{
                console.log(err)
                ElMessage.error(filenames.value.filename + "下载失败");
              })
          
       }

       const jsonData = ref([])
        //查看json数据
        const handleEdit= name =>{
            // const get_id = name +1
            // console.log(tableData.value)
            
            jsonData.value = []
            dialogVisibles.value = true
            jsonData.value =JSON.parse(JSON.parse(JSON.stringify(name.docs)).replace(/'/g, '"'))
            // if (tableData.value.length > 0){
                
            //     for (let item of tableData.value){
            //     if (JSON.parse(JSON.stringify(item)).id === get_id ){
            //       jsonData.value =JSON.parse(JSON.parse(JSON.stringify(item)).docs.replace(/'/g, '"'))
            //     }
            //   }
            // }else{
            //   jsonData.value = []
            // }
      }
       return{
            tableData,
            tableRowClassName,
            dialogVisible,
            dialogVisibles,
            filechange,
            file_input,
            handleExceed,
            ruleForm,
            upload_file,
            isButtonDisabled,
            filename,
            down_template,
            handleEdit,
            refresh_date,
            jsonData,
            handleDown,
            file_date_list
       }
    },
});

