import axios from 'axios';
import { ElMessage } from 'element-plus';


enum MEGS{
    "操作成功" = 200,
    "用户或密码错误" = 201,
    "服务器开小差了" = 500
}
// 获取token
const tokens = localStorage.getItem("token")
const $http_date = axios.create({
    baseURL: window['ApiBaseUrl'],
    timeout: 10000,
    headers: {
          "Content-Type": "multipart/form-data",
          "Access-Control-Allow-Origin": "*",
          "token": tokens
    }
})


$http_date.interceptors.request.use(config=>{
    config.headers = config.headers || {}
    if (localStorage.getItem("token")){
        // console.log(localStorage)
        config.headers.token = localStorage.getItem("token") || ""
    }
    return config
})

$http_date.interceptors.response.use(res=>{
    console.log(res)
    const code:number = res.data.status_code
    console.log(code)
    if (code == 2001){
        // ElMessage.error(res.data.msg)
        return Promise.reject(res.data.msg)
    }
    return res
},err=>{
    return Promise.reject(MEGS[500])
})

export default $http_date