<template>
  <el-config-provider :locale="locale">
  <router-view/>
  </el-config-provider>
</template>

<script setup>
import zhCn from 'element-plus/lib/locale/lang/zh-cn'
import { ref } from "vue";
let locale=ref(zhCn)


</script>
<style lang="scss">
*{
   padding: 0px;
   margin: 0px;
}
html,body,#app{
    width: 100%; 
    height: 100%;
    /* background-color: #272d39; */
}
</style>
