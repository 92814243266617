import $http from './index';
import $http_date from './indexs';
import $http_file from './index_file'
import {FormUpdate} from "../numtype/fromdate"

interface  loginData {
     email:string,
     password:string,
     codes:string,
}

interface registerData{
     email:string,
     password:string,
     che_password:string,
     code_num:string,
}
interface codeData{
     email:string,

}

//登录
export const logins =(data:loginData)=>$http({url:"/login",method:"post",data})
//注册
export const registers =(data:registerData)=>$http({url:"/registers",method:"post",data})
//获取验证码
export const getcode =(data:codeData)=>$http({url:"/code",method:"post",data})
//数据查询接口
export const getdata =  (data:any) =>$http({url:"/SearchMapping",method:"post",data})
// 文件上传接口
export const uploadcheunkFile = (data:any) =>$http_date({url:"/upload_file",method:"post",data})
//文件下载接口
export const down_file = (data:any) =>$http_file({url:"/down_template",method:"post",data})
//历史数据请求
export const history_date = (data:any) =>$http({url:"/show_history",method:"post",data})








// 文件上传校验接口
export const checkChunkFile = (data:any) =>$http({url:"http://fusioncd.x3322.net:8081/api/checkfile",method:"post",data})
// 文件合并接口
export const mergeFile = (data:any) => $http({url:"http://fusioncd.x3322.net:8081/api/update_com",method:"post",data})
// 文件确认保存接口
export const saveFile = (data:any) => $http({url:"http://fusioncd.x3322.net:8081/api/add_file",method:"post",data})
